export const Image = {
  bg1: require("./bg1.png"),
  bg2: require("./bg2.png"),
  bg3: require("./bg3.png"),
  m1: require("./Cow.png"),
  m2: require("./milk.png"),
  m3: require("./milk (1).png"),
  homebg: require("./homebg.png"),
  1 : require('./1.png'),
  2 : require('./2.png'),
};
